<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="urut" label="Order Number" v-model="urut"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('urut')">{{errors.first('urut')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" name="prefix" label="Prefix" v-model="prefix"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('prefix')">{{errors.first('prefix')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <small class="ml-2">Type</small>
          <v-select v-model="type" @input="typeChange" :options="types" name="type" label="name" :reduce="e => e.id"></v-select>
          <span class="text-danger text-sm" v-show="errors.has('type')">{{errors.first('type')}}</span>
        </div>
      </div>
      <div v-if="type_state == 'date'" class="vx-row mb-5">
        <div class="vx-col w-full">
          <small class="ml-2">Format Date</small>
          <v-select v-model="format_data" :options="formatDates" name="format_data" label="name" :reduce="e => e.id"></v-select>
          <span class="text-danger text-sm" v-show="errors.has('format_data')">{{errors.first('format_data')}}</span>
        </div>
      </div>
      <div v-if="type_state == 'counter'" class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="last_value" label="Last Value" v-model="last_value"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('last_value')">{{errors.first('last_value')}}</span>
        </div>
      </div>
      <div v-if="type_state == 'roman'" class="vx-row mb-5">
        <div class="vx-col w-full">
          <small class="ml-2">Format Roman</small>
          <v-select v-model="format_data" :options="formatRomans" name="format_data" label="name" :reduce="e => e.id"></v-select>
          <span class="text-danger text-sm" v-show="errors.has('format_data')">{{errors.first('format_data')}}</span>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import vSelect from 'vue-select'

export default {
  props:{
    company_id: {
        type: String,
        default: null
    },
    transaction_type_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  components:{
    vSelect
  },
  data(){
    return {
      urut: "",
      prefix: "",
      type: "",
      format_data: "",
      last_value: 0,
      type_state: "",
      types: [
          { id: 'date', name: 'Date' },
          { id: 'counter', name: 'Counter' },
          { id: 'roman', name: 'Roman' },
      ],
      formatDates: [
          {id:'Y',name:'Year 4-Digit (XXXX)'},
          {id:'y',name:'Year 2-Digit (XX)'},
          {id:'m',name:'Month (01-12)'},
          {id:'F',name:'Month Text (January-December)'},
          {id:'d',name:'Day (01-31)'}
      ],
      formatRomans: [
          {id:'Y',name:'Year 4-Digit (XXXX)'},
          {id:'y',name:'Year 2-Digit (XX)'},
          {id:'m',name:'Month (01-12)'}
      ]
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'company_numberings/store',
      dispatchUpdate: 'company_numberings/update',
      dispatchShow: 'company_numberings/show',
    }),
    typeChange(value) {
      this.type_state = value
      this.format_data = ""
      this.last_value = 0
    },
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          company_id: this.company_id,
          transaction_type_id: this.transaction_type_id,
          urut: this.urut,
          prefix: this.prefix,
          type: this.type,
          format_data: this.format_data,
          last_value: this.last_value
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          if(error.status == 422) {
              for(let x in error.data) {
                  this.$vs.notify({
                    title: 'Oops!',
                    text: error.data[x][0],
                    color: 'danger'
                  })
                  break
              }
          } else {
              if(error.status == 422) {
                  for(let x in error.data) {
                      this.$vs.notify({
                        title: 'Oops!',
                        text: error.data[x][0],
                        color: 'danger'
                      })
                      break
                  }
              } else {
                  this.$vs.notify({
                    title: 'Oops!',
                    text: error.data.message,
                    color: 'danger'
                  })
              }
          }
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.urut = data.urut
      this.prefix = data.prefix
      this.type = data.type
      this.format_data = data.format_data
      this.last_value = data.last_value
      this.type_state = data.type
    }
  },
  mounted(){
    // console.log(this.ids)
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>